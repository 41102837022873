
import React, { useState, useContext } from "react";
import UserPrefsContext from "../../context//UserPrefs/UserPrefsContext";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "chartjs-plugin-datalabels";
import {Scatter} from 'react-chartjs-2';
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import filterEVs from "../../functions/vehicle/Filter/filterEVs";
import totalRange from "../../client_customizations/assets/images/Total_Range.svg"
import electricRange from "../../client_customizations/assets/images/Electric_Range.svg"
import totalRangeActive from "../../client_customizations/assets/images/Total_Range_Active.svg"
import electricRangeActive from "../../client_customizations/assets/images/Electric_Range_Active.svg"
import {FormatCarName, FormatAsThousands} from "../../utils/Helpers/Format"
import getPaymentDetails from "../../functions/vehicle/getPaymentDetails"
import { FormattedMessage } from "react-intl"

const CostVsRangeGraph = ({electricVehicles}) => {

    const userPrefs = useContext(UserPrefsContext);
    const [renderTooltip, setRenderTooltip] = useState(false)
    const [renderEvs, setRenderEvs] = useState([])
    const [toggleRange, setToggleRange] = useState(true) 

    if (!electricVehicles) return <LoadingSpinner />;

    const filteredAndSortedElectricVehicles = filterEVs(electricVehicles, {
        vehicleFormFactorFilters: userPrefs.get("vehicleFormFactorFilters"),
        vehicleFuelTypeFilters: userPrefs.get("vehicleFuelTypeFilters"),
        vehicleMakeFilter: userPrefs.get("vehicleMakeFilter"),
        vehicleAgeFilter: userPrefs.get("vehicleAgeFilter")
    });

    if (!filteredAndSortedElectricVehicles.length) {
        return (
            <div className="text-center">
            <p className="h4">
                <FormattedMessage
                    id="vehicles.none"
                    defaultMessage="No vehicles found"
                    description="No vehicles found"
                />
            </p>
            </div>
        );
    }


    const dataCoordiantes = filteredAndSortedElectricVehicles.map(ev => {
        let paymentDetails = getPaymentDetails(
        ev,
        userPrefs
        )

        if (toggleRange) {
            return {x: ev.total_range, y: paymentDetails.afterIncentives}
        } else {
            return {x: ev.electric_range, y: paymentDetails.afterIncentives}
        }
    })

    const dataImages = filteredAndSortedElectricVehicles.map(ev => {
        let evImage = new Image()
        evImage.src = `https://zappyassets.s3.amazonaws.com/img/vehicles/icons/${ev.handle}.png`
        return evImage
    })

    const data = {
        labels: ['Scatter'],
        datasets: [
          {
            label: 'EVs',
            showLine: false,
            fill: false,
            backgroundColor: 'rgba(75,192,192,0.4)',
            pointBackgroundColor: 'yellow',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            pointStyle: dataImages,
            data: dataCoordiantes,
            datalabels: {
                display: false
            }
          }
        ]
      };

    const showTooltip = (tooltip) => {
        if (tooltip.opacity === 0) {
            // Leaving the tooltip
            setRenderTooltip(false)

        } else {
            // ENTER THE TOOPTIP
            let highlightedEvs = tooltip.dataPoints.map( dataPoint => {
                return filteredAndSortedElectricVehicles[dataPoint.index]
            })

            setRenderEvs(highlightedEvs)
            setRenderTooltip(true)
        }
    }

    const renderTooltips = () => {
        return renderEvs.map(ev => {
            let paymentDetails = getPaymentDetails(
                ev,
                userPrefs
                )
            return (
                <div key={ev.electric_vehicle_id} className="renderToolTip">
                    <img  style={{paddingTop: "20px", paddingBottom: "20px"}}src={ev.images[0].url_full} alt={ev.display_name}/>
                    <div className="tooltipTextContainer">
                        <Link
                            to={`/vehicles/${ev.handle}`}
                            className="tooltipEvName"
                            style={{textDecoration:"none"}}
                        >
                            {FormatCarName(ev)}
                        </Link>
                        <div className="tooltipDescription">
                            <p>
                                <FormattedMessage
                                    id="graph.costVsRange.afterIncentives"
                                    defaultMessage="From at {price} after incentives"
                                    description="afterIncentives subtitle"
                                    values={{
                                        price: FormatAsThousands(paymentDetails.afterIncentives)
                                    }}
                                />
                            </p>
                            <p>
                                {process.env.REACT_APP_METRIC_SYSTEM ?
                                    <FormattedMessage
                                        id="graph.costVsRange.electricKilometerRange"
                                        defaultMessage="Electric Range: {kilometers} kilometers"
                                        description="Electric range subtitle"
                                        values={{
                                            kilometers: ev.electric_range
                                        }}
                                    /> :
                                    <FormattedMessage
                                        id="graph.costVsRange.electricRange"
                                        defaultMessage="Electric Range: {miles} miles"
                                        description="Electric range subtitle"
                                        values={{
                                            miles: ev.electric_range
                                        }}
                                    />}
                            </p>
                        </div>

                    </div>
                </div>
            )
        })
    }

    const handleClick = (e) => {
        if (e.target.dataset.toggle === "Total Range"){
            setToggleRange(true)
        } else {
            setToggleRange(false)
        }
    }

    return (
        <div className="cost-vs-range-graph">
            <Scatter 
                data={data} 
                options= {{
                    scales: {
                        y: {
                            type: 'linear',
                            position: 'bottom',
                            scaleLabel: {
                                display: true,
                                labelString: "MSRP After Incentives",
                                fontSize: 20,
                                fontColor: "#2b2b2b"
                            },
                            ticks: {
                                //   max: 160000,
                                //   min: 0,
                                  // Include a dollar sign in the ticks
                                  callback: function(value, index, values) {
                                      var formatter = new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                        minimumFractionDigits: 0, /* this might not be necessary */
                                      });
                                      return formatter.format(value);
                                  },
                                  fontSize: 16
                              }
                        },
                        x: {
                            // max: max_x,
                            min: 0,
                            scaleLabel: {
                                display: true,
                                labelString: process.REACT_METRIC_SYSTEM ? toggleRange ? "Total Range (kilometers)" : "Electric Range (kilometers)" : toggleRange ? "Total Range (miles)" : "Electric Range (miles)",
                                fontSize: 20,
                                fontColor: "#2b2b2b"
                            },
                            ticks: {
                              fontSize: 16
                            }
                        }
                    },
                    tooltips: {
                        enabled: false,
                        custom: showTooltip
                    },
                    // Displays a Legend at the top of the graph
                    legend: {
                        display: false
                    }
                }}
            />
            <div className="rangeToggle" style={{paddingTop: "20px", paddingBottom:"20px"}}>
                <button
                    type="button"
                    className={toggleRange ? "toggle-btn-active" : "toggle-btn"}
                    data-toggle="Total Range"
                    onClick={handleClick}
                >
                    <img 
                        className={toggleRange ? "evs-toggle-image toggle-image-active" : "evs-toggle-image toggle-image-passive"} 
                        data-toggle="Total Range"
                        alt="Lightning Bolt and Gasoline Icon"
                        src={toggleRange ? totalRangeActive : totalRange}>
                    </img>
                    <img 
                        className={toggleRange ? "evs-toggle-image toggle-image-passive" : "evs-toggle-image toggle-image-hover"}
                        data-toggle="Total Range"
                        alt="Lightning Bolt and Gasoline Icon"
                        src={totalRangeActive}>
                    </img>
                    <FormattedMessage
                        id="graph.title.totalRange"
                        defaultMessage="Total Range"
                        description="Total Range Title"
                    />
                </button>
                <button
                    type="button"
                    className={toggleRange ? "toggle-btn" : "toggle-btn-active"}
                    data-toggle="Electric Range"
                    onClick={handleClick}
                >
                    <img 
                        className={toggleRange ? "evs-toggle-image toggle-image-passive" : "evs-toggle-image toggle-image-active"}
                        data-toggle="Electric Range"
                        alt="Lightning Bolt Icon"
                        src={toggleRange ? electricRange : electricRangeActive}>
                    </img>
                    <img 
                        className={toggleRange ? "evs-toggle-image toggle-image-hover" : "evs-toggle-image toggle-image-passive"}
                        data-toggle="Electric Range"
                        alt="Lightning Bolt Icon"
                        src={electricRangeActive}>
                    </img>
                    <FormattedMessage
                        id="graph.title.electricRange"
                        defaultMessage="Electric Range"
                        description="Electric Range Title"
                    />
                </button>
            </div>

            {renderTooltip ? renderTooltips() : ""}
        </div>
    )
}

export default CostVsRangeGraph

CostVsRangeGraph.propTypes = {
    electricVehicles: PropTypes.array
}
