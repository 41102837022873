import React, {useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext"
import MonthlyFuelCostChart from "../CostsCharts/MonthlyFuelCostChart";
import FuelRangeChart from "../CostsCharts/FuelRangeChart";
import CostToOwnComparison from "../CostsCharts/CostToOwnComparison";
import CostOfOwnershipOptions from "./../CostOfOwnershipOptions/CostOfOwnershipOptions";
import { FormatCarName } from "./../../utils/Helpers/Format";
import ShowHideButton from "../ShowHideButton/ShowHideButton";
import CalcPanels from "./CalcPanels/CalcPanels"
import RenderAssumption from "../RenderAssumption/RenderAssumption";
import {
  SALES_TAX,
  GASOLINE_PRICE_IN_DOLLARS_PER_GAL,
  DOWN_PAYMENT,
  ELECTRICITY_RATE_IN_DOLLARS_PER_KWH
} from  "../../client_customizations/data/assumptions/ASSUMPTIONS"
import { FormattedMessage, useIntl } from 'react-intl';
import BreakevenChart from "../CostsCharts/BreakevenChart";
import fetchWrapper from "../../utils/fetch/fetchWrapper";

const CostOfOwnership = ({ cars, title, comparison, usedEv, noTitle }) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  const [isLoading, setIsLoading] = useState(true);
  const [insuranceDataApi, setInsuranceData] = useState(null);
  const [maintenanceDataApi, setMaintenanceData] = useState(null);
  const [cache, setCache] = useState({ insurance: {}, maintenance: {} });

  useEffect(() => {
    const loadInsuranceData = async (vehicle_handle) => {
      if (cache.insurance[vehicle_handle]) {
        return { [vehicle_handle]: cache.insurance[vehicle_handle] };
      }

      const params = {
        postcode: userPrefs.get('zipcode'),
      };
      let url = new URL(
        `${process.env.REACT_APP_API_HOST}/vehicles/${vehicle_handle}/insurance`
      );
      let searchParams = new URLSearchParams(params);
      url.search = searchParams;
      try {
        const insuranceData = await fetchWrapper(url, {
          method: 'GET',
        });
        setCache((prevCache) => ({
          ...prevCache,
          insurance: {
            ...prevCache.insurance,
            [vehicle_handle]: insuranceData.yearly_insurances,
          },
        }));
        setIsLoading(false);

        return { [vehicle_handle]: insuranceData.yearly_insurances };
      } catch (error) {
        console.log('Failed to load insurance data.');
        console.log(error);
        setIsLoading(false);
      }
      return null;
    };

    const loadMaintenanceData = async (vehicle_handle) => {
      if (cache.maintenance[vehicle_handle]) {
        return { [vehicle_handle]: cache.maintenance[vehicle_handle] };
      }

      const params = {
        annualmiles: userPrefs.get('milesDrivenAnnually'),
        postcode: userPrefs.get('zipcode'),
      };
      let url = new URL(
        `${process.env.REACT_APP_API_HOST}/vehicles/${vehicle_handle}/maintenance`
      );
      let searchParams = new URLSearchParams(params);
      url.search = searchParams;
      try {
        const maintenanceData = await fetchWrapper(url, {
          method: 'GET',
        });
        setMaintenanceData(maintenanceData.maintenance.costs);
        setCache((prevCache) => ({
          ...prevCache,
          maintenance: {
            ...prevCache.maintenance,
            [vehicle_handle]: maintenanceData.maintenance.costs,
          },
        }));
        setIsLoading(false);

        return { [vehicle_handle]: maintenanceData.maintenance.costs };
      } catch (error) {
        console.log('Failed to load maintenance data.');
        console.log(error);
        setIsLoading(false);
      }
      return null;
    };

    const loadAllData = async () => {
      setIsLoading(true);
      const insuranceResults = {};
      const maintenanceResults = {};

      for (let car of cars) {
        const insuranceData = await loadInsuranceData(car.handle);
        const maintenanceData = await loadMaintenanceData(car.handle);

        if (insuranceData) {
          insuranceResults[car.handle] = insuranceData[car.handle];
        }
        if (maintenanceData) {
          maintenanceResults[car.handle] = maintenanceData[car.handle];
        }
      }

      setInsuranceData(insuranceResults);
      setMaintenanceData(maintenanceResults);
      setIsLoading(false);
    };

    if (cars && cars.length > 0) {
      loadAllData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cars, userPrefs]);

  if (!cars || cars.length === 0) return null;

  const car = cars[0]
  const equivalentGasVehicle = cars[1];

  SALES_TAX.title = intl.formatMessage({ id: "assumption.salesTax", defaultMessage: "Sales Tax"})
  SALES_TAX.value = userPrefs.get("salesTax");
  SALES_TAX.valueAsFormatted =
    (userPrefs.get("salesTax") * 100).toFixed(2) + "%";
  GASOLINE_PRICE_IN_DOLLARS_PER_GAL.title = intl.formatMessage({ id: "assumption.gasolinePrice", defaultMessage: "Price of Gasoline"})
  DOWN_PAYMENT.title = intl.formatMessage({ id: "assumption.downPayment", defaultMessage: "Down Payment"})
  DOWN_PAYMENT.valueAsFormatted = intl.formatMessage({ id: "assumption.downPaymentValue", defaultMessage: "10% of MSRP + Tax"})
  ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.title = intl.formatMessage({ id: "assumption.electricityRate", defaultMessage: "Electricity Rate"})
  ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.valueAsFormatted = `$0.1793/kWh` 

  return (
    <section className="container" id="CostOfOwnership">
      {!noTitle && <div className="CostOfOwnership">
        <div className="row">
          <div className={`col-sm-12 ${comparison ? `` : `text-center`}`}>
            <p className={usedEv ? `h1 p-3` : `h1`} style={{margin: '30px 0 32px 0'}}>{title ||               
              <FormattedMessage 
                id="ev.compareGasVehicle"
                defaultMessage="Compare the {car} to a similar gas vehicle, the {gasCar}"
                description="Description of Colton Recharged"
                values= {{
                  car: <span style={{color: '#005996'}}>{FormatCarName(car)}</span>,
                  gasCar: FormatCarName(equivalentGasVehicle)
                }}
              />}</p>

          </div>
        </div>
      </div>}
      <div className="row">
        <div className="col-md-4 col-lg-3" style={{ textAlign: "left" }}>
          <p style={{fontStyle: 'normal', fontSize: '18px', fontWeight: 400, color: '#33333'}}>
            <FormattedMessage 
                id="graph.costAnalysis"
                defaultMessage="Cost Analysis"
                description="Cost Analysis"
              />
          </p>
          <CostOfOwnershipOptions cars={cars} usedEv={usedEv}/>
          <br />
          <div className="input-well d-none d-md-block" style={{padding: '25px 15px'}}>
            <p className="RenderItemTitle" style={{ marginBottom: 10 }}>
              <FormattedMessage 
                id="graph.assumptions"
                defaultMessage="Assumptions"
                description="Assumptions"
              />
            </p>

            {process.env.REACT_APP_SLIDE_SALES_TAX &&<RenderAssumption assumption={SALES_TAX} />}
            { (userPrefs.get("purchaseMethod") === "cash" || usedEv) 
            ? (null)
            : (<RenderAssumption assumption={DOWN_PAYMENT} />) 
            }
            <RenderAssumption
              assumption={ELECTRICITY_RATE_IN_DOLLARS_PER_KWH}
            />
            <p className="legal-disclaimer">
              <small>
                <FormattedMessage 
                  id="ev.electricityRateCalc"
                  defaultMessage="The electricity rate is calculated as a blended rate based off an average household consumption of 500 kWh per month. Your actual electricity rate will vary depending on your monthly usage. To learn more about GWP’s rates, please click {website}"
                  description="Compare Vehicle Text"
                  values= {{
                    website: 
                      <a 
                        href={process.env.REACT_APP_ELECTRICITY_RATE_URL}
                        target="_blank"
                        rel="noopener noreferrer"> 
                          here. 
                      </a>
                  }}
                />
              </small>
            </p>
          </div>
        </div>
        <div
          id="costs-chart-areas"
          aria-live="polite"
          aria-atomic="true"
          role="region"
          style={{ position: "absolute", top: "-9999px" }}
        >
          <span>
            <FormattedMessage
              id="results.updated"
              defaultMessage="Results have been updated as of "
              description="Results have been updated as of "
            />{new Date().toLocaleString()}.
          </span>
        </div>
        <div className="col-md-8 col-lg-9" style={{marginTop: '44px'}}>
          {process.env.REACT_APP_PAGES_HOME_GRAPH_COST_TO_FILL_ENABLED 
            ? (<MonthlyFuelCostChart 
                cars={cars} 
                comparison={comparison} 
              />)
            : (null)}
          <br />
          {process.env.REACT_APP_PAGES_HOME_GRAPH_COST_TO_OWN_COMPARISON && !isLoading && insuranceDataApi ? (
          <CostToOwnComparison 
            cars={cars}
            hasExtraPadding
            comparison={comparison}
            insuranceData={insuranceDataApi}
            maintenanceData={maintenanceDataApi}
          />) : (null)}
          <br />
          {process.env.REACT_APP_PAGES_HOME_GRAPH_BUDGT_ENABLED 
            ? ( 
              <FuelRangeChart 
                car={car} 
              />) 
            : (null)}
          <br />
          {process.env.REACT_APP_PAGES_BREAKEVEN_GRAPH && insuranceDataApi && maintenanceDataApi?
            <BreakevenChart 
              cars={cars}
              insuranceData={insuranceDataApi}
              maintenanceData={maintenanceDataApi}
            /> : 
            null
          }          
          {!comparison && 
            <p className="text-left">
              <FormattedMessage 
                id="ev.carDetails.compareText"
                defaultMessage="We selected the {gasVehicle} based on its
                proximity to the {vehicle} based on brand, size, and price."
                description="Compare Vehicle Text"
                values= {{
                  gasVehicle: FormatCarName(equivalentGasVehicle),
                  vehicle: FormatCarName(car),
                }}
              />
            </p>
          }
          <ShowHideButton margin="16px 0" isFullWidth buttonText={` ${intl.formatMessage({ id: "sources", defaultMessage: "Sources"})}`} displayAlertOnlyOnMobile>
              <div className="input-well">
                <CalcPanels
                  cars={cars}
                />
              </div>
            </ShowHideButton>
        </div>
      </div>
    </section>
  );
};

export default CostOfOwnership;

CostOfOwnership.propTypes = {
  car: PropTypes.object
};
